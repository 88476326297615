import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { BehaviorSubject, filter, interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  private readonly CHECK_INTERVAL_MS = 60 * 1000; // Check every 1 minutes
  public showUpdateDialog$ = new BehaviorSubject<boolean>(false);

  constructor(private swUpdate: SwUpdate) {
    this.initializeUpdateCheck();
  }

  private initializeUpdateCheck() {
    console.log(this.swUpdate.isEnabled);
    // Ensure Service Worker is enabled
    if (this.swUpdate.isEnabled) {
      // Start checking for updates at defined intervals
      this.setupPeriodicUpdateCheck();
      // Listen for version updates
      this.listenForVersionUpdates();
    }
  }

  private setupPeriodicUpdateCheck() {
    interval(this.CHECK_INTERVAL_MS).subscribe(() => {
      this.swUpdate.checkForUpdate().then(() => {
        console.log('Update check completed');
      });
    });
  }

  private listenForVersionUpdates() {
    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
      )
      .subscribe((evt) => {
        console.log('New version available:', evt);
        this.showUpdateDialog$.next(true); // Notify user about the update
      });
  }

  public applyAppUpdate() {
    this.swUpdate.activateUpdate().then(() => {
      console.log('App update applied, reloading...');
      document.location.reload();
    });
  }
}
