export const dark_properties = {
  //  ============== main ================
  '--main-background': '#0E1117',
  //  ================ Login ================
  '--login-header-background': '#111921',
  '--login-popup-background': '#111921',
  '--login-popup-color': '#818f99',
  '--login-popup-button-hover': '#c9cfd4',
  '--login-popup-contact-mail': '#3275b8',
  //  ================ Layout ================
  //  ================ header ================
  '--header-background': '#1A2430',
  '--header-button-background-expand': '#e5181b',
  '--header-button-focus': '#E8ECED',
  '--header-logo': '#E8ECED',
  '--header-fa-icon': '#818f99',
  // ================  footer  ================
  '--footer-background-color': '#1A2430',
  '--footer-mid-text': '#818899',
  //  ================ navbar ================
  '--navbar-background': '#1A2430',
  '--navbar-button-background-hover': '#0E1117',
  //  ================ Home ================
  '--home-background': '#1A2430',
  //  ================ My projects ================
  '--my-project-background': '#111921',
  '--my-project-color': '#818f99',
  '--my-project-button-CE-background': '#e0e3e6',
  '--my-project-button-CE-color': '#141d27',
  '--my-project-border-input-text': '#3b4957',
  '--my-project-box-shadow-input-text': '#3b4957',
  '--my-project-map-background': 'aliceblue',
  // ================== MyProjects.Table
  '--my-project-table-header-background': '#2b3847',
  '--my-project-table-header-color': '#818f99',
  '--my-project-table-header-border-color': '#3b4957',
  '--my-project-table-header-icon-sort-active': '#3275b8',
  '--my-project-table-header-icon-sort-color': '#f7f8f9',
  '--my-project-table-body-background': '#1a2430',
  '--my-project-table-body-background-2': '#1b2327',
  '--my-project-table-body-color': '#c9cfd4',
  '--my-project-table-button-menu-active': '#2b3847',
  '--my-project-table-button-menu-border-active': '#3275bb',
  '--my-project-menu-overlay-background': '#141d27',
  '--my-project-menu-overlay-border': '#3b4957',
  '--my-project-menu-overlay-background-hover': '#2B3847',
  '--my-project-menu-overlay-color': '#C9CFD4',
  '--my-project-button-selected-BG': '#2b3847',
  '--my-project-button-selected-border': '#3275bb',
  '--myproject-table-scrollbar': '#3B4957',
  '--myproject-table-hightlight-prooject-column': '#3275B8',
  '--myproject-table-active-status': '#32B877',
  '--myproject-table-completed-status': '#818F99',

  //==================Mandatory.Well-details
  '--create-epx-well-detail-error-msg': '#dd7d89',
  '--create-epx-well-detail-title-color': '#c9cfd4',
  '--create-epx-well-detail-border-color': '#3b4957',
  '--create-epx-well-detail-dropdown-background-color': '#141d27',
  '--create-epx-well-detail-border-color-hover': '#3b82f6',
  '--create-epx-well-detail-background-dropdown': '#2b3847',
  '--create-epx-well-detail-color-dropdown': '#c9cfd4',
  '--create-epx-well-detail-border-dropdown': '#dd7d89',
  '--create-epx-well-detail-radiobtn-highlight-border': '#fff',
  '--create-epx-well-detail-radiobtn-background': '#000',
  '--create-epx-well-detail-radiobtn-box-background': 'rgb(252, 0, 0)',
  // Mandatory.related-experience
  '--related-experience-menu-remove-color': '#a50f22',
  '--related-experience-menu-text-color': '#c9cfd4',
  '--related-experience-checkbox-border': '#c9cfd4',
  '--related-experience-checkbox-color': '#c9cfd4',
  // Mandatory.related-intervention
  '--related-intervention-menu-remove-color': '#a50f22',
  '--related-intervention-filter-dialog-footer': '#141d27',
  '--related-intervention-filter-input-border': '#3b4957',
  '--related-intervention-filter-input-color': '#c9cfd4',
  '--related-intervention-filter-button-background': '#e0e3e6',
  '--related-intervention-filter-datepicker-background': '#141d27',
  '--related-intervention-filter-datepicker-color': '#c9cfd4',
  '--related-intervention-filter-datepicker-header-background': '#141d27',
  '--related-intervention-filter-datepicker-nextbtn-color': '#e8eced',
  '--related-intervention-filter-datepicker-year-color': '#e8eced',
  '--related-intervention-filter-datepicker-span-highlight-color': '#c9cfd4',
  '--related-intervention-filter-datepicker-span-highlight-background':
    '#1b334b',
};
