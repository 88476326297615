export const environment = {
  production: true,
  redirectUri: 'https://qa.yoda.ienergy.halliburton.com',
  clientId: 'f80b0905-15b5-4b8b-b6b9-eaf138bc3b5e',
  authority:
    'https://login.microsoftonline.com/b7be7686-6f97-4db7-9081-a23cf09a96b5',
  scopes: 'f80b0905-15b5-4b8b-b6b9-eaf138bc3b5e/.default',
  vapidPublicKey:
    'BPNLY7kjoP-nY5hAXL9SF5XUVZCNJKq_VCTd8kdP6fBhT2uF4s-sz-p46OVRw3WAallGc_P-TikfiD2Uy6-Idhs',
  api: 'https://qa-api.yoda.ienergy.halliburton.com',
};
