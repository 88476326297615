import { Component } from '@angular/core';
import { TypeButton } from '@shared/models/button.model';
import { AppUpdateService } from '@shared/services/app-update.service';
import { TypeClassButton } from '../../models/button.model';

@Component({
  selector: 'yoda-app-update-dialog',
  template: `
    <div class="content">
      <p>
        There is a new version of the app available. Please reload to update.
      </p>
      <div class="controls">
        <yoda-button
          label="OK"
          [type]="typeButton.button"
          [typeClass]="typeClassButton.confirm"
          [disabled]="false"
          (clicked)="reload()"
        ></yoda-button>
      </div>
    </div>
  `,

  styleUrls: ['./app-update-dialog.component.scss'],
})
export class AppUpdateDialogComponent {
  typeButton = TypeButton;
  typeClassButton = TypeClassButton;
  constructor(private updateService: AppUpdateService) {}

  reload() {
    this.updateService.applyAppUpdate();
  }
}
